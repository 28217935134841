import { userInfo } from 'os';
import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';

const canvasStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
};

function getAnimationSettings(angle, originX) {
  return {
    particleCount: 3,
    angle,
    spread: 255,
    origin: { x: originX },
    colors: ['#a864fd', '#eeeeee'],
  };
}

export default function Confetti() {
  const refAnimationInstance = useRef(null);
  const [intervalId, setIntervalId] = useState(undefined);
  const [noMore, setNoMore] = useState(false);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const nextTickAnimation = useCallback(() => {
    if (refAnimationInstance.current) {
      refAnimationInstance.current(getAnimationSettings(60, 0));
      refAnimationInstance.current(getAnimationSettings(120, 1));
    }
  }, []);

  const startAnimation = useCallback(() => {
    if (!intervalId) {
      setTimeout( () => {
          setIntervalId(setInterval(nextTickAnimation, 3));
      }, 500);
      setTimeout(() => {
        setNoMore(true)
        pauseAnimation();
      }, 3000);
    }
  }, [nextTickAnimation, intervalId]);

  const pauseAnimation = useCallback(() => {
    clearInterval(intervalId);
    setIntervalId(null);
  }, [intervalId]);

  const stopAnimation = useCallback(() => {
    clearInterval(intervalId);
    refAnimationInstance.current && refAnimationInstance.current.reset();
  }, [intervalId]);

  useEffect(() => {
    if (!noMore) {
        startAnimation();
        setTimeout(pauseAnimation, 2000);
    }
    return () => {
      clearInterval(intervalId);
    };
}, [intervalId]);

  return (
    <>
      <ReactCanvasConfetti refConfetti={getInstance} style={{...canvasStyles}} />
    </>
  );
}
